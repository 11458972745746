import { getDefaultOptions } from 'API/auth'
import { getResource } from 'API/getResource'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { Form } from 'components/Form/Form'
import { navigate, PageProps } from 'gatsby'
import { useEditableHook } from 'hooks/useEditableHook'
import React from 'react'
import { CustomModal } from 'components/common/useModal'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import { useGetModulesHook } from 'hooks/useGetModulesHook'
import { addFileToCDN } from './addFileToCDN'

const resourceDates = [{ text: 'Visible From:', name: 'visible_from' }]

const Resource: React.FC<PageProps> = ({ params: { id: course }, location }) => {
	let ref = React.useRef<HTMLButtonElement | null>(null)

	const { type_of_course, stable } = useGetCourseDetails(course)
	const { modules } = useGetModulesHook({ course, stable, type_of_course })

	const { edit, elem_id } = useEditableHook({ location })
	const [editable, setEditable] = React.useState<Record<string, any>>({})
	React.useEffect(() => {
		if (edit === 'true' && elem_id !== '') {
			const run = async () => {
				const asset = await getResource(elem_id, 'Teacher')
				if (asset) {
					console.log('asset', asset)
					setEditable({ ...asset })
				}
			}
			run()
		}
	}, [edit, elem_id])
	return (
		<>
			<CustomModal
				header='Add Resource'
				onClick={() => {
					// if (edit === 'true') {
					// 	toast('ToDo', { type: 'info' })
					// 	return
					// }
					console.log(ref)
					if (ref.current) {
						ref.current.click()
					}
				}}
			>
				<Form
					editableState={editable}
					getRef={(_ref) => {
						if (_ref) ref = _ref
					}}
					action={async ({ state }) => {
						if (edit === 'true') {
							// if (edit === 'true') {
							// toast('ToDo', { type: 'info' })
							console.log({ state })
							let state_copy = Object.assign({}, state)

							if (state.file) {
								const resp = await addFileToCDN(state)
								if (resp) {
									state_copy = { ...state_copy, cdn: resp.uid }
									delete state_copy['file']
								}
							}
							state_copy = { ...state_copy, course }
							const resource = await handleApi<{ uid: string }>(
								axios.patch(
									getUrl('asset/resource'),
									{
										...state_copy,
									},
									getDefaultOptions()
								)
							)
							if (resource) navigate(`/course/${course}/activities/resource/${resource.uid}`)
							return
							// }
						}
						const resp = await addFileToCDN(state)
						if (resp) {
							const state_copy = Object.assign({}, state)
							delete state_copy['file']

							const resource = await handleApi<{ uid: string }>(
								axios.post(
									getUrl('asset/resource'),
									{
										...state_copy,
										cdn: resp.uid,
										course,
									},
									getDefaultOptions()
								)
							)
							if (resource) navigate(`/course/${course}/activities/resource/${resource.uid}`)
						}
					}}
					text={'Add Resource'}
					fields={[
						{
							type: 'text',
							label: 'Name',
							name: 'name',
						},
						{
							type: 'textarea',
							label: 'Description',
							name: 'description',
						},
						...resourceDates.map((elem) => ({
							type: 'datetime-local',
							label: elem.text,
							name: elem.name,
						})),
						{
							type: 'image',
							name: 'file',
							label: 'Add file',
							optional: edit === 'true',
						},
						{
							type: 'select',
							selection: modules,
							name: 'module',
							label: 'Module',
						},
					]}
				></Form>
			</CustomModal>
		</>
	)
}

export default Resource
